<template>
  <div v-if="questionGroups">
    <base-table
        v-if="shownData.length"
        :columns="questionGroupFields"
        :rows="shownData"
    />

    <table-pagination
        v-if="shownData.length"
        :totalPages="1"
        :countOnPage="countOnPage"
        :total="rowData.length"
        :count="countOnPage > shownData.length ? shownData.length : countOnPage"
        @changePage="changePage"
    />
    <div v-else class="no_results">
      <p>Нет результатов</p>
    </div>
  </div>
  <div v-else class="table-preloader">
    <preloader/>
  </div>
</template>

<script>
import {ref} from "@vue/reactivity";
import {useStore} from "vuex";
import {computed, onBeforeMount} from "@vue/runtime-core";
import Preloader from "@/components/Technical/Preloader.vue";
import TablePagination from "@/components/OtherElements/TablePagination.vue";
import BaseTable from "@/components/Tables/BaseTable.vue";

export default {
  name: "qgroup-table",
  components: {
    BaseTable,
    TablePagination,
    Preloader,
  },
  setup() {
    const store = useStore(),
        views = {
          table: "Таблица",
          tabs: "История",
          list: "Список",
          icons: "Иконки",
        };

    const countOnPage = ref(15),
        page = ref(1),
        isPreloader = ref(false);
    const questionGroups = computed(() => store.state.test.questionGroups),
        rowData = computed(() => {
          return questionGroups.value.map((group) => {
            return {
              id: group.id,
              image: group.image,
              text: group.text,
              type: views[group.view],
              edit: group.id,
            };
          });
        });

    const questionGroupFields = [
      {
        field: "image",
        headerName: "Картинка",
      },
      {
        field: "text",
        headerName: "Текст",
      },
      {
        field: "type",
        headerName: "Вид",
      },
      {
        field: "edit",
        headerName: "",
        dest: "test/question-group",
        res: "edit",
      },
    ];

    const shownData = computed(() => {
      return rowData.value ? rowData.value.slice(pageDataStart.value, pageDataStart.value + countOnPage.value) : [];
    });

    const pageDataStart = ref(0);

    const changePage = (page) => {
      pageDataStart.value = (page - 1) * countOnPage.value;
    };

    onBeforeMount(() => {
      store.dispatch("test/getQuestionGroups");
    });
    return {
      countOnPage,
      isPreloader,
      page,
      changePage,
      questionGroups,
      views,

      rowData,

      questionGroupFields,
      shownData

    };
  },
};
</script>

<style lang="scss" scoped>
</style>
